import React from 'react';
import Layout from '../components/Layout';
import { ReplaceComponentRendererArgs, Link } from 'gatsby';
import styled from 'styled-components';
import { formatPostDate, formatReadingTime } from '../lib/utils/helpers';
import SEO from '../components/SEO';
import { variables } from '../lib/styles/variables';

const PostTemplateBlock = styled.section`
  a {
    text-decoration: none;
    color: ${variables.linkColor};

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
`;

const Header = styled.header`
  margin-bottom: 32px;
`;

const PostTitle = styled.h1`
  color: ${variables.primaryColor};
  margin: 0 0 8px 0;
`;

const Small = styled.small``;

const Nav = styled.nav`
  ul {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0;
    li {
      list-style-type: none;
    }
  }
`;

const NavLink = styled(Link)`
  text-decoration: none;
  color: ${variables.linkColor};

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

export type TemplateProps<T> = ReplaceComponentRendererArgs['props'] & {
  pageContext: {
    isCreatedByStatefulCreatePages: boolean;
  } & T;
};

export type PostTemplateProps = TemplateProps<{
  date: Date;
  title: string;
  description: string;
  html: string;
  timeToRead: number;
  previous: PostFrontmatter | null;
  next: PostFrontmatter | null;
  tableOfContents: string;
  slug: string;
}>;

export type PostFrontmatter = {
  title: string;
  slug: string;
};

const PostTemplate = React.memo((props: PostTemplateProps) => {
  const { date, title, description, html, timeToRead, next, previous, tableOfContents, slug } = props.pageContext;

  return (
    <Layout>
      <SEO title={title} description={description} />
      <Header>
        <PostTitle>{title}</PostTitle>
        <Small>{`${formatPostDate(date)} • ${formatReadingTime(timeToRead)}`}</Small>
      </Header>
      <PostTemplateBlock>
        <section dangerouslySetInnerHTML={{ __html: html }} />
      </PostTemplateBlock>
      <Nav>
        <ul>
          <li>
            {previous && (
              <NavLink to={previous.slug} rel="prev">
                ← {previous.title}
              </NavLink>
            )}
          </li>
          <li>
            {next && (
              <NavLink to={next.slug} rel="next">
                {next.title} →
              </NavLink>
            )}
          </li>
        </ul>
      </Nav>
    </Layout>
  );
});

export default PostTemplate;
